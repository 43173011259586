<template>
  <div>
    <el-container class="home">
      <!--侧边栏-->
      <div class="home-aside" :class="hideAside ? 'hideAside' : '' ">
        <el-scrollbar wrap-class="scrollbar-wrapper">
          <Menu :menu-data="menuData" :is-collapse="hideAside"></Menu>
        </el-scrollbar>
      </div>
      
      <el-main class="home-main" :class="hideAside ? 'hideAside' : '' ">
        <div class="main">
          <div class="topHead">
            <div class="expand" @click="hideAside = !hideAside">
              <el-icon v-if="hideAside"><Expand /></el-icon>
              <el-icon v-else><Fold /></el-icon>
            </div>
            <!-- <Breadcrumb class="breadcrumb" separator="/"></Breadcrumb> -->
            <el-dropdown @command="(command) => { handleCommand(command); }">
              <div style="display: flex;padding-right: 20px;">
                <span class="el-dropdown-link"><el-icon style="vertical-align: bottom;"><UserFilled /></el-icon>&nbsp;{{ name }}</span>
                <el-icon><ArrowDown /></el-icon>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="1"><el-icon><SwitchButton /></el-icon>退出登录</el-dropdown-item>
                  <!-- <el-dropdown-item command="2"><el-icon><Unlock /></el-icon>修改密码</el-dropdown-item> -->
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <router-view />

          <!-- <router-view v-slot="{ Component }">
            <Transition mode="out-in">
              <component :is="Component" />
            </Transition>
          </router-view> -->

          <!-- <router-view v-slot="{ Component, route }">
            <transition mode="out-in" appear>
              <component :is="Component" :key="route.path" />
            </transition>
          </router-view> -->
        </div>
      </el-main>
    </el-container>

    <el-dialog v-model="showModify" title="修改密码" width="350px">
      <el-form label-position="right" label-width="85px">
        <el-form-item label="修改密码：">
          <el-input v-model="item.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：">
          <el-input v-model="item.password_confirmation" type="password"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showModify = false">取消</el-button>
          <el-button type="primary" @click="commitModify">确定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
  import { onMounted, reactive, toRefs, watch } from 'vue'
  import { routes } from '@/router'
  import Menu from './menu'
  import Breadcrumb from '@/components/Breadcrumb'
  import { SwitchButton, ArrowDown, Unlock, UserFilled, Expand, Fold } from '@element-plus/icons'
  import { useRouter } from 'vue-router'
  import { get, post } from "@/api/request"
  import { ElMessage } from 'element-plus'
  
  export default {
    components: {
      Menu,
      Breadcrumb,
      SwitchButton,
      ArrowDown,
      Unlock,
      Expand,
      Fold,
      UserFilled
    },
    setup() {
      const data = reactive({
        title: '',
        name: localStorage.getItem('userName'),
        item: {},
        menuData: [],
        hideAside: false,
        showModify: false
      })

      const router = useRouter()
      //监听路由名称
      watch(
        () => router.currentRoute.value.meta.title,
        (toPath, old) => {
          data.title = toPath
        },
        {
          deep: true,
          immediate: true
        }
      )

      const NewRoutes = (() => {
        const retRoutes = []
        for (const value of routes) {
          if (value.path === '/') {
            if (value.children && value.children.length) {
              // 将children中的路由记录往上提一层,方便<SidebarItem>渲染
              for (const routeItem of value.children) {
                const obj = Object.assign({}, routeItem)
                obj.path = `/${routeItem.path}`
                if(obj.name == 'manage') {
                  obj.hidden = localStorage.getItem('isSuper') == 0
                }
                retRoutes.push(obj)
              }
            }
          } else {
            retRoutes.push(value)
          }
        }
        console.log(retRoutes)
        return retRoutes
      })

      const handleCommand = (type) => {
        if (type == 1) {
          loginOut()
        } else if(type == 2) {
          data.item = {}
          data.showModify = true
        }
      }

      const commitModify = () => {
        post('/api/staffs/password', data.item).then(() => {
          ElMessage({ type: 'success', message: '修改成功' })
          data.showModify = false
          loginOut()
        })
      }
      
      const loginOut = () => {
        get('/api/auth/logout').then(() => {
          localStorage.removeItem('token')
          localStorage.removeItem('saveTime')
          router.replace({
            path: '/login'
          })
        })
      }

      onMounted(async () => {
        data.menuData = JSON.parse(localStorage.getItem('menusList'))
      })
      
      return {
        ...toRefs(data),
        handleCommand,
        commitModify
      }
    }
  }
</script>

<style lang="scss" scoped>
  .breadcrumb{
    padding: 0 20px;
  }
  .topHead{
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E5E5E5;
    background-color: #fff;
    .expand{
      padding: 10px;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .home {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: block;
    &-header {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      // position: absolute;
      border-bottom: 1px solid #e5e5e5;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0;
      &-title {
        display: flex;
        align-items: center;
        // justify-content: center;
        padding-left: 17px;
        box-sizing: border-box;
        background-color: #FAFAFA;
        color: #fff;
        width: 100%;
        height: 40px;
        white-space: nowrap;
      }

      &-right {
        display: flex;
        justify-content: space-between;
        flex: 1;
        .expand{
          padding: 10px;
          box-sizing: border-box;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          cursor: pointer;
        }
        &-btn {
          height: 40px;
          // border-bottom: 1px solid #ececec;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
        }
      }
    }

    &-aside {
      height: 100%;
      width: 200px;
      background-color: #F9FAFC;
      transition: width 0.3s;
      position: fixed;
      overflow: hidden;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      .home-header-title{
        width: 200px;
      }
      &.hideAside{
        width: 64px;
        .home-header-title{
          padding: 0;
          width: 64px;
          justify-content: center;
        }
      }
      &-menu {
        border: none;
      }
    }

    &-main {
      position: relative;
      transition: margin-left 0.28s;
      margin-left: 200px;
      padding: 0;
      box-sizing: border-box;
      height: 100%;
      background-color: #ecf0f5;
      &.hideAside{
        // width: calc(100% - 64px);
        margin-left: 64px;
      }
      .main {
        box-sizing: border-box;
      }
    }
  }
  .el-icon {
    &:hover{
      color: #409eff;
    }
  }
  .el-menu{
    border-right: none;
  }
  .v-enter-active,
  .v-leave-active {
    transition: all 0.5s ease;
  }
  .v-leave-to,
  .v-enter-from {
    opacity: 0;
    transform: translateX(-30px);
  }
  .v-leave-from,
  .v-enter-to {
    transform: translateX(0px);
  }
</style>

<style>
  .home-header-right-btn .el-dropdown--small{
    display: flex;
    align-items: center;
  }
  .home-aside .el-aside{
    height: calc(100vh - 40px);
    overflow-x: hidden;
  }
  .el-menu-item{
    height: 50px !important;
    line-height: 50px !important;
  }
  .el-sub-menu__title{
    width: 200px;
    height: 50px !important;
    line-height: 50px !important;
  }
  .hideAside .el-sub-menu__title{
    width: 64px;
  }
  .home-aside .el-sub-menu .el-sub-menu__icon-arrow{
    width: 12px;
  }
  .el-sub-menu .el-menu-item{
    padding: 0 0 0 45px !important;
  }
  .el-menu--popup .el-sub-menu .el-menu-item{
    padding: 0 0 0 20px !important;
  }
  .el-menu .el-sub-menu .el-menu .el-sub-menu .el-menu .el-menu-item{
    padding: 0 0 0 55px !important;
  }
  .el-menu--popup .el-sub-menu__icon-arrow{
    width: 12px !important;
  }
</style>
