<template>
  <div class="pageBox">
    <div class="searchBox">
      <div class="row row1">
        <span class="span">商品名称：</span>
        <div class="right"><el-input v-model="filter.keyword" class="wid100" clearable></el-input></div>
      </div>
      <div class="row row1">
        <span class="span">商品编码：</span>
        <div class="right"><el-input v-model="filter.goods_code" class="wid100" clearable></el-input></div>
      </div>
      <div class="row row1">
        <span class="span">规格编码：</span>
        <div class="right"><el-input v-model="filter.sku_code" class="wid100" clearable></el-input></div>
      </div>
      <div class="row row1">
        <span class="span">是否单品：</span>
        <div class="right">
          <el-select v-model="filter.single" placeholder="请选择" clearable class="wid100">
            <el-option label="组合商品" :value="0" />
            <el-option label="单品" :value="1" />
          </el-select>
        </div>
      </div>
      <div class="row row1">
        <span class="span">是否售卖：</span>
        <div class="right">
          <el-select v-model="filter.on_sale" placeholder="请选择" clearable class="wid100">
            <el-option label="是" :value="1" />
            <el-option label="否" :value="0" />
          </el-select>
        </div>
      </div>
      <div class="row">
        <el-button type="primary" @click="handleSearch"><el-icon><Search /></el-icon>&nbsp;筛选</el-button>
      </div>
    </div>
    <el-card shadow="never">
      <el-table :data="goodsList" style="width: 100%" border v-loading="loading">
        <el-table-column prop="id" label="ID" align="center" width="80" />
        <el-table-column prop="type" label="商品信息">
          <template #default="scope">
            <div>{{ scope.row.goods_name }}</div>
            <span style="color: #666;">{{ scope.row.goods_code }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="goods_stock" label="商品库存" align="center" /> -->

        <el-table-column prop="type" label="规格信息">
          <template #default="scope">
            <div>{{ scope.row.sku_name }}</div>
            <span style="color: #666;">{{ scope.row.sku_code }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sku_number" label="规格数量" align="center" />
        <el-table-column prop="sku_stock" label="规格库存" align="center" />

        <el-table-column prop="type" label="是否单品" align="center">
          <template #default="scope">
            <el-tag type="primary" v-if="scope.row.single === 0">组合商品</el-tag>
            <el-tag type="danger" v-else-if="scope.row.single === 1">单品</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="是否售卖" align="center">
          <template #default="scope">
            <el-switch
              v-model="scope.row.on_sale"
              :active-value="1"
              :inactive-value="0"
              active-text="是"
              inactive-text="否"
              inline-prompt
              :loading="scope.row.loading"
              :before-change="()=>beforeChange($event, scope.row)" />
          </template>
        </el-table-column>
        
        <!-- <el-table-column prop="created_at" label="创建时间" align="center" /> -->
      </el-table>
      <div class="page-pagination">
        <el-pagination
          :current-page="page"
          background
          layout="prev, pager, next, sizes, total"
          :total="total"
          :page-sizes="[10, 50, 100, 500, 1000]"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"></el-pagination>
      </div>
    </el-card>

  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue"
import { get, post } from "@/api/request"
import { Search } from '@element-plus/icons'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  components: {
    Search
  },
  setup() {
    const data = reactive({
      filter: {},
      goodsList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false
    })

    function handleSearch() {
      data.page = 1
      fetchData()
    }

    const fetchData = () => {
      data.loading = true
      let params = {
        ...data.filter,
        page: data.page,
        pageSize: data.pageSize
      }
      get(`/api/mxErpGoods`, params).then((res) => {
        data.goodsList = res.data
        data.total = res.meta.total
        data.loading = false
      }).catch(() => {
        data.loading = false
      })
    }

    function handleCurrentChange(e) {
      data.page = e
      fetchData()
    }

    function handleSizeChange(e) {
      data.page = 1
      data.pageSize = e
      fetchData()
    }

    const beforeChange = (e, row) => {
      let on_sale = row.on_sale
      row.loading = true
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm('确定修改该状态吗？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          post(`/api/mxErpGoods/${row.id}`, {on_sale: on_sale == 1 ? 0 : 1}, 'PUT').then(() => {
            ElMessage({ type: 'success', message: '修改成功' })
            row.loading = false
            return resolve(true)
          }).catch(() => {
            row.loading = false
            return reject(new Error('Error'))
          })
        }).catch(() => {
          row.loading = false
          return reject(new Error('Error'))
        })
      })
    }

    onMounted(() => {
      fetchData()
    })

    return {
      ...toRefs(data),
      handleSearch,
      handleCurrentChange,
      handleSizeChange,
      fetchData,
      beforeChange
    }
  }
}
</script>

<style lang="scss" scoped>
  .searchBox{
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 15px 0 0 0;
    border-radius: 4px;
    margin-bottom: 15px;
    .row{
      display: flex;
      align-items: center;
      width: auto;
      box-sizing: border-box;
      margin-bottom: 15px;
      margin-right: 15px;
      &.row1{
        width: 300px;
      }
      .span{
        display: block;
        width: 80px;
        font-size: 14px;
        text-align: right;
        box-sizing: border-box;
      }
      .right{
        width: calc(100% - 80px);
      }
    }
  }
</style>
  