export const whiteList = [
  {
    path: '/commodity/list',
    name: 'commodityList',
    full_path: 'views/commodity/list.vue',
    component: () => import('views/commodity/list')
  },
  {
    path: '/commodity/all',
    name: 'allgoods',
    full_path: 'views/commodity/all.vue',
    component: () => import('views/commodity/all')
  },
  {
    path: '/commodity/sku',
    name: 'commoditySku',
    full_path: 'views/commodity/sku.vue',
    component: () => import('views/commodity/sku')
  },
  {
    path: '/commodity/combination',
    name: 'combination',
    full_path: 'views/commodity/combination.vue',
    component: () => import('views/commodity/combination')
  },
  {
    path: '/commodity/brand',
    name: 'commodityBrand',
    full_path: 'views/commodity/brand.vue',
    component: () => import('views/commodity/brand')
  },
  {
    path: '/purchase/index',
    name: 'purchase',
    full_path: 'views/purchase/index.vue',
    component: () => import('views/purchase/index')
  },
  {
    path: '/warehouse/index',
    name: 'warehouse',
    full_path: 'views/warehouse/index.vue',
    component: () => import('views/warehouse/index')
  },
  {
    path: '/warehouse/io',
    name: 'warehouseIO',
    full_path: 'views/warehouse/io.vue',
    component: () => import('views/warehouse/io')
  },
  {
    path: '/supplier/index',
    name: 'supplier',
    full_path: 'views/supplier/index.vue',
    component: () => import('views/supplier/index')
  },
  {
    path: '/statistics/index',
    name: 'statistics',
    full_path: 'views/statistics/index.vue',
    component: () => import('views/statistics/index')
  },
  {
    path: '/permission/role',
    name: 'role',
    full_path: 'views/permission/role.vue',
    component: () => import('views/permission/role')
  },
  {
    path: '/permission/menus',
    name: 'menus',
    full_path: 'views/permission/menus.vue',
    component: () => import('views/permission/menus')
  },
  {
    path: '/shops/index',
    name: 'shops',
    full_path: 'views/shops/index.vue',
    component: () => import('views/shops/index')
  }
]

export const constantRouterComponents = {
  'commodityList': () => import('views/commodity/list'),
  'allgoods': () => import('views/commodity/all'),
  'commoditySku': () => import('views/commodity/sku'),
  'combination': () => import('views/commodity/combination'),
  'commodityBrand': () => import('views/commodity/brand'),
  'purchase': () => import('views/purchase/index'),
  'warehouse': () => import('views/warehouse/index'),
  'warehouseIO': () => import('views/warehouse/io'),
  'supplier': () => import('views/supplier/index'),
  'statistics': () => import('views/statistics/index'),
  'role': () => import('views/permission/role'),
  'menus': () => import('views/permission/menus'),
  'shops': () => import('views/shops/index')
}