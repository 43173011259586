<template>
  <template v-for="(menu, i) in menuData" :key="menu.uri">
    <el-menu-item :index="menu.uri" v-if="!menu.children">
      <div class="icon" v-if="menu.parent_id == 0">
        <img v-if="menu.icon" :src="menu.icon">
      </div>
      <template #title><span :class="menu.parent_id == 0 ? 'span' : ''">{{menu.title}}</span></template>
    </el-menu-item>
    <el-sub-menu :index="menu.uri" v-else popper-class="popper-meun">
      <template #title>
        <div class="icon" v-if="menu.parent_id == 0">
          <img v-if="menu.icon" :src="menu.icon">
        </div>
        <span :class="menu.parent_id == 0 ? 'span' : 'sp1'">{{menu.title}}</span>
      </template>
      <menu-tree :menu-data="menu.children" />
    </el-sub-menu>
  </template>
</template>

<script>
export default {
  props: {
    menuData: Array
  },
  setup() {
    return {}
  }
}
</script>

<style scoped lang="scss">
  .icon{
    width: 24px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 20px;
    top: 0;
    
  }
  .icon img{
    width: 17px;
  }
  .sp1{
    padding-left: 10px;
  }
</style>

<style scoped>
  .el-menu-item ::v-deep .span, ::v-deep .el-sub-menu__title .span{
    padding-left: 30px;
    position: relative;
    z-index: 10;
  }
</style>